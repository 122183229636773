import React, { useRef, useState } from "react";
import LogoImg from "static/imgs/logo.svg";
import { HOME_URL } from "../../common/ApiUrl";
import { NavLink, useNavigate } from "react-router-dom";

const LoginPage = () => {
  const [loginCode, setLoginCode] = useState("");
  const [isLoginFail, setIsLoginFail] = useState(false);
  const navigate = useNavigate();

  const onChange = (e) => {
    setLoginCode(e.target.value);
  };

  const login = (loginCode, event) => {
    if (event.keyCode === 13) {
      fetch("https://api.newsalji.com/api/v1/auth/providers/signin", {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          loginCode,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            localStorage.setItem("Authorization", data.data.accessToken);
            navigate(HOME_URL);
          } else {
            setIsLoginFail(true);
          }
        });
    }
  };

  return (
    <div className="flex justify-center items-center h-screen w-screen flex-col">
      <div>
        <img
          src="https://rg-rn-app.s3.ap-northeast-2.amazonaws.com/logos/verticalLogoWithText.png"
          width={150}
        />
      </div>
      <div className="mt-5">
        <input
          onChange={onChange}
          type="password"
          placeholder="로그인 코드를 입력해주세요."
          className={`input  ${
            isLoginFail ? "input-error" : "input-primary"
          } max-w-xs w-[286px] h-[46px] bg-gray-200 
          border-gray-400 active:border-gray-400
          focus:border-gray-400 text-gray-800
          hover:border-gray-400
          `}
          onKeyDown={(e) => login(loginCode, e)}
        />
      </div>
      <div className="mt-3">
        <button
          onClick={() => login(loginCode, { keyCode: 13 })}
          className="btn btn-primary w-[286px] h-[44px] text-gray-100"
        >
          로그인
        </button>
      </div>
    </div>
  );
};

export default LoginPage;
