import React, { useState } from "react";
import Picker from "emoji-picker-react";
import { useRecoilState } from "recoil";

import EmojiAddBtnImg from "static/imgs/emojiAddBtn.svg";
import { paragraphState } from "../../state";

const ParagraphInputBox = ({ onRemove, paragraph }) => {
  const [paragraphs, setParagraphs] = useRecoilState(paragraphState);

  const [isShowingEmojiAddBtn, setIsShowingEmojiAddBtn] = useState(true);
  const [isShowingEmojiPicker, setIsShowingEmojiPicker] = useState(false);

  const onEmojiClick = (event, emojiObject) => {
    setParagraphs(
      paragraphs.map((p) =>
        p.id === paragraph.id ? { ...p, titleEmoji: emojiObject.emoji } : p
      )
    );
    setIsShowingEmojiAddBtn(false);
    setIsShowingEmojiPicker(false);
  };

  const onClickEmojiAddBtn = () => {
    setIsShowingEmojiPicker(!isShowingEmojiPicker);
  };

  const onChangeContentTitle = (e) => {
    setParagraphs(
      paragraphs.map((p) =>
        p.id === paragraph.id ? { ...p, title: e.target.value } : p
      )
    );
  };

  const onChangeContentContent = (e) => {
    setParagraphs(
      paragraphs.map((p) =>
        p.id === paragraph.id ? { ...p, content: e.target.value } : p
      )
    );
  };

  return (
    <div>
      <div className="my-6">
        <div className="text-left font-semibold my-2">
          <span className="text-gray-800 text-xl">문단</span>
          <span className="text-gray-600 text-xs mx-2">
            *제목 최소 2자 이상 18자 이하 / 내용 2자 이상
          </span>
          {paragraph.id !== 1 ? (
            <span
              className="text-[#D64A59] ml-[60px] text-xs hover:cursor-pointer"
              onClick={() => onRemove(paragraph.id)}
            >
              문단 삭제
            </span>
          ) : null}
        </div>
        <div className="flex">
          <div
            onClick={onClickEmojiAddBtn}
            className="border-solid border-[1px] border-[#594BFE] rounded-[5px] w-[58px] h-[48px] mr-[5px] hover:cursor-pointer text-center items-center"
          >
            <img
              src={EmojiAddBtnImg}
              className={`w-[30px] mx-auto h-[48px] ${
                isShowingEmojiAddBtn ? "" : "hidden"
              }`}
            />
            <span className="text-[28px] leading-[48px]">
              {paragraph.titleEmoji}
            </span>
          </div>
          <input
            type="text"
            name="title"
            value={paragraph.title}
            onChange={onChangeContentTitle}
            placeholder="제목을 입력해주세요"
            className={`input input-bordered input-primary w-full`}
          />
        </div>
        {isShowingEmojiPicker ? (
          <Picker onEmojiClick={onEmojiClick} preload={true} />
        ) : undefined}
        <textarea
          value={paragraph.content}
          onChange={onChangeContentContent}
          placeholder="내용을 입력해주세요"
          className={`textarea textarea-primary w-full h-52 mt-[6px] mb-[3px]`}
        />
        <hr />
      </div>
    </div>
  );
};

export default ParagraphInputBox;
