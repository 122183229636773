import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import infoCircle from "static/imgs/infoCircle.svg";

interface Post {
  id: number;
  postTitle: string;
  subTitle: string;
  categoryName: string;
  publishedStartDate: string;
}

const defaultPost = {
  id: 0,
  title: "",
  subTitle: "",
  publishedStartDate: "",
  category: {
    name: "",
  },
  provider: {
    groupNickname: "",
  },
  postImgs: [
    {
      link: "",
    },
  ],
  publisherName: "",
  postContents: [
    {
      content: "",
      title: "",
      titleEmoji: "",
    },
  ],
  hyperlink: "",
};

const PostTable = () => {
  const authorization = localStorage.getItem("Authorization");
  const [posts, setPosts] = useState<any>([]);
  const [deleteTargetId, setDeleteTargetId] = useState(0);
  const [targetPost, setTargetPost] = useState(defaultPost);
  const [imgIdx, setImgIdx] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetch("https://api.newsalji.com/api/v1/posts/provider", {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: authorization ? "Bearer " + authorization : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        const posts = data.data;
        setPosts(
          posts.map((post) => {
            return {
              ...post,
              publishedStartDate: dayjs(post.createdAt).format(
                "YY년 MM월 DD일"
              ),
            };
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const deletePost = (postId: number) => {
    fetch(`https://api.newsalji.com/api/v1/posts/${postId}`, {
      method: "DELETE",
      headers: {
        "content-type": "application/json",
        Authorization: authorization ? "Bearer " + authorization : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        alert("소식이 삭제되었습니다.");
        location.reload();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const imageIndexHandler = () => {
    // 마지막 사진인 경우
    if (imgIdx === targetPost.postImgs.length - 1) {
      setImgIdx(0);
      return;
    }
    setImgIdx(imgIdx + 1);
  };

  return (
    <>
      <input type="checkbox" id="delete-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <h3 className="font-bold text-lg">정말 삭제하시겠습니까?</h3>
          <p className="py-4">삭제된 소식은 복구할 수 없습니다.</p>
          <div className="modal-action">
            <label htmlFor="delete-modal" className="btn">
              취소
            </label>
            <button
              className="btn btn-primary"
              onClick={() => deletePost(deleteTargetId)}
            >
              삭제
            </button>
          </div>
        </div>
      </div>

      <input type="checkbox" id="detail-modal" className="modal-toggle" />
      <div className="modal">
        <div className="modal-box">
          <span className="text-xs text-gray-800">
            *상기 페이지는 작성한 내용 확인용으로, 실제 노출되는 디자인과는
            차이가 있을 수 있습니다.
          </span>
          <div className="h-4"></div>
          <span className="text-sm text-gray-500 bg-gray-300 p-2 rounded-md">
            {targetPost.category.name}
          </span>
          <h3 className="font-bold text-lg my-4">{targetPost.title}</h3>
          <span className="text-sm text-gray-500">
            {targetPost.provider.groupNickname}
          </span>
          <div>
            {targetPost.postImgs[imgIdx] && (
              <img
                src={targetPost.postImgs[imgIdx].link}
                style={{ width: "100%", height: "auto", marginBottom: 20 }}
              />
            )}
          </div>
          {targetPost.postImgs.length === 0 ? (
            <span className="text-sm text-gray-500">
              {"이미지가 없습니다. 기본 이미지로 대체됩니다."}
            </span>
          ) : (
            <button
              className="btn btn-secondary btn-sm mb-4"
              onClick={() => imageIndexHandler()}
            >
              {"사진 넘기기"}
            </button>
          )}
          <br />
          <div className="text-xs text-primary bg-gray-300 p-2 rounded-xl mt-4 flex">
            <img src={infoCircle} width={20} />
            <span className="mt-[2px] ml-1">
              소식 출처: {targetPost.publisherName}
            </span>
          </div>
          {targetPost.postContents.map((x) => (
            <div className="mt-4" key={x.title}>
              <div className="flex">
                <span>{x.titleEmoji ?? "📍"}</span>
                {"  "}
                <span className="text-lg font-bold ml-1">{x.title}</span>
              </div>
              <span className="text-sm text-gray-800">{x.content}</span>
            </div>
          ))}
          {targetPost.hyperlink && (
            <button className="w-full btn btn-primary mt-4">
              <a href={targetPost.hyperlink}>자세히보기</a>
            </button>
          )}

          <div className="modal-action">
            <label htmlFor="detail-modal" className="btn">
              닫기
            </label>
          </div>
        </div>
      </div>
      <div className="w-full flex mb-4 justify-between">
        <span className="text-lg text-gray-800 font-bold">소식 관리</span>
        <button
          className="btn btn-primary btn-sm font-bold"
          onClick={() => navigate("/posts/create?step=1")}
        >
          + 소식 발행하기
        </button>
      </div>
      <div className="text-gray-600 text-sm mb-4">
        <span>*제목을 누르면 발행 상세 내용을 조회할 수 있습니다.</span>
        <br />
        <span>
          *소식 발행 즉시, 카테고리 알림을 설정한 사용자에게 푸시알림이
          전송됩니다.
        </span>
        <br />
        <span className="text-gray-600 text-sm">
          *업데이트 반영을 위해 서비스를 사용하시기 전에{" "}
          <strong>Mac: command + shift + R</strong>,{" "}
          <strong>Window: ctrl + shift + r</strong>을 실행해주세요.
        </span>
      </div>
      <div className="overflow-x-auto">
        <table className="table w-full ">
          <thead>
            <tr className=" text-gray-800 ">
              <th className="bg-gray-300 text-sm">No.</th>
              <th className="bg-gray-300 text-sm">제목</th>
              <th className="bg-gray-300 text-sm">카테고리</th>
              <th className="bg-gray-300 text-sm">날짜</th>
              <th className="bg-gray-300 text-sm"></th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post) => {
              return (
                <tr key={post.id}>
                  <td>{post.id}</td>
                  <td>
                    <div onClick={() => setTargetPost(post)}>
                      <label htmlFor="detail-modal" className="cursor-pointer">
                        <p className="font-bold">{post.title}</p>
                        <p className="text-sm">{post.subTitle}</p>
                      </label>
                    </div>
                  </td>
                  <td>{post.category.name}</td>
                  <td>{post.publishedStartDate}</td>
                  <td>
                    <label
                      htmlFor="delete-modal"
                      className="btn modal-button hover:bg-gray-300 border-none text-gray-500"
                      onClick={() => setDeleteTargetId(post.id)}
                    >
                      삭제
                    </label>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default PostTable;
