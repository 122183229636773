import React from "react";

const Footer = () => {
  return (
    <div className="w-[100%]">
      <footer className="footer footer-left py-10 px-[5%] bg-slate-200 text-gray-500">
        <div>
          <p>주식회사 디나비 | 대표 : 김태윤 | 사업자 등록번호 282-88-02535</p>
          <p>서울특별시 금천구 범안로 1130, 507-1호 | 연락처 02-853-7145</p>
          <p>알지 | 개인정보보호책임자 : 정경준</p>
          <p>고객문의 help@newsalji.com | 대표메일 : newsalji@newsalji.com</p>
          <p className="mt-4">© 2022. D.NABI All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
