import React, { useRef, useState } from "react";
import { useRecoilState } from "recoil";

import ParagraphInputBox from "./ParagraphInputBox";
import { paragraphState } from "../../state";

const ParagraphInputBoxContainer = () => {
  const [paragraphs, setParagraphs] = useRecoilState(paragraphState);
  const id = useRef(1);

  const onRemove = (id) => {
    setParagraphs(paragraphs.filter((paragraph) => paragraph.id !== id));
  };

  const onClickParagraphAddBtn = () => {
    const newParagraph = {
      id: id.current + 1,
      title: "",
      content: "",
      titleEmoji: null,
    };
    id.current += 1;
    setParagraphs(paragraphs.concat(newParagraph));
  };

  return (
    <div>
      {paragraphs.map((paragraph) => {
        return (
          <ParagraphInputBox
            paragraph={paragraph}
            onRemove={onRemove}
            key={paragraph.id}
          />
        );
      })}

      <button
        className="btn btn-primary w-full"
        onClick={onClickParagraphAddBtn}
      >
        문단 추가
      </button>
    </div>
  );
};

export default ParagraphInputBoxContainer;
