import React, { useState, useCallback } from "react";
import InputBox from "./InputBox";
import SelectBox from "./SelectBox";
import { NavLink } from "react-router-dom";
import { CREATE_POST_STEP2_URL } from "../../common/ApiUrl";
import { useRecoilState } from "recoil";
import {
  titleState,
  subtitleState,
  creatorState,
  categoryIdState,
  linkState,
} from "../../state";

const FirstContainer = () => {
  const [title, setTitle] = useRecoilState(titleState);
  const [subtitle, setSubTitle] = useRecoilState(subtitleState);
  const [creator, setCreator] = useRecoilState(creatorState);
  const [categoryId, setCategoryId] = useRecoilState(categoryIdState);
  const [link, setLink] = useRecoilState(linkState);
  const [disabled, setDisabled] = useState(false);

  const [inputsError, setInputsError] = useState({
    // 제목 error
    title: false,
    // 소제목 error
    subtitle: false,
    // 소식 생성 주체 error
    creator: false,
    // 카테고리 id error
    categoryId: false,
    // 외부 링크 error
    link: false,
  });

  const onChangeTitle = (e) => {
    const { value, name } = e.target;

    if (value.length < 6 || value.length > 24) {
      setInputsError({ ...inputsError, [name]: true });
    } else {
      setInputsError({ ...inputsError, [name]: false });
    }

    setTitle(value);
  };

  const onChangeSubTitle = (e) => {
    const { value, name } = e.target;

    if (value.length < 6 || value.length > 24) {
      setInputsError({ ...inputsError, [name]: true });
    } else {
      setInputsError({ ...inputsError, [name]: false });
    }

    setSubTitle(value);
  };

  const onChagneCreator = (e) => {
    const { value, name } = e.target;

    if (value.length > 10) {
      setInputsError({ ...inputsError, [name]: true });
    } else {
      setInputsError({ ...inputsError, [name]: false });
    }

    setCreator(value);
  };

  const onChagneLink = (e) => {
    setLink(e.target.value);
  };

  const onChangeInSelectBox = (e) => {
    const { value } = e.target;
    setCategoryId(value ? Number(value) : value);
    setInputsError({
      ...inputsError,
      categoryId: false,
    });
  };

  const validateBeforeNextStep = (e) => {
    if (!categoryId) {
      e.preventDefault();
      setInputsError({
        ...inputsError,
        categoryId: true,
      });
    }

    if (inputsError.creator) {
      e.preventDefault();
    }

    if (!subtitle || inputsError.subtitle) {
      e.preventDefault();
      setInputsError({
        ...inputsError,
        subtitle: true,
      });
    }

    if (!title || inputsError.title) {
      e.preventDefault();
      setInputsError({
        ...inputsError,
        title: true,
      });
    }
    setDisabled(false);
  };

  return (
    <>
      <div className="my-3 w-full">
        <ul className="steps w-full">
          <li className="step step-primary"></li>
          <li className="step"></li>
          <li className="step"></li>
        </ul>
      </div>
      <div className="text-sm text-primary">
        step 1. 각 항목에 맞게 소식 정보를 입력해 주세요.
      </div>
      <InputBox
        name="title"
        value={title}
        onChange={onChangeTitle}
        title="제목 (필수)"
        subTitle="*최소 6자 이상 최대 24자 이하"
        placeHolder="제목을 입력해주세요"
        isError={inputsError.title}
        maxLength={24}
      />
      {inputsError.title && (
        <div className="text-red-600 text-xs mx-2 flex">
          글자수를 확인해주세요
        </div>
      )}
      <InputBox
        name="subtitle"
        value={subtitle}
        onChange={onChangeSubTitle}
        title="소제목 (필수)"
        subTitle="*최소 6자 이상 최대 24자 이하"
        placeHolder="소제목을 입력해주세요"
        isError={inputsError.subtitle}
        maxLength={24}
      />
      {inputsError.subtitle && (
        <div className="text-red-600 text-xs mx-2 flex">
          글자수를 확인해주세요
        </div>
      )}
      <InputBox
        name="creator"
        value={creator}
        onChange={onChagneCreator}
        title="소식 출처"
        subTitle="*10자 이하"
        placeHolder="ex) 경기도교육청"
        isError={inputsError.creator}
        maxLength={10}
      />
      {inputsError.creator && (
        <div className="text-red-600 text-xs mx-2 flex">
          글자수를 확인해주세요
        </div>
      )}
      <SelectBox
        onChange={onChangeInSelectBox}
        isError={inputsError.categoryId}
      />
      <InputBox
        name="url"
        value={link}
        onChange={onChagneLink}
        title="URL 링크"
        subTitle=""
        placeHolder="자세히보기 버튼 클릭 시, 해당 링크로 연결됩니다."
        maxLength={50}
      />

      <NavLink to={CREATE_POST_STEP2_URL} onClick={validateBeforeNextStep}>
        <button
          className={`btn ${
            disabled ? "btn-disabled" : "btn-primary"
          } w-full my-6`}
        >
          다음
        </button>
      </NavLink>
    </>
  );
};

export default FirstContainer;
