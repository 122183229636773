import React, { useEffect, useState } from "react";
import Layout from "../../components/common/Layout";

function ProfilePage() {
  const [logo, setLogo] = useState("");
  const [groupNickname, setGroupNickname] = useState("");
  const authorization = localStorage.getItem("Authorization");

  useEffect(() => {
    fetch(`https://api.newsalji.com/api/v1/auth/providers`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: authorization ? "Bearer " + authorization : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setLogo(data.data.logoImgUrl);
        setGroupNickname(data.data.groupNickname);
      });
  }, []);

  const clickChangeGroupNicknameBtn = () => {
    const newGroupNickname = window.prompt(
      "변경할 소속명의 이름을 입력해주세요."
    );
    fetch(`https://api.newsalji.com/api/v1/auth/providers`, {
      method: "PUT",
      headers: {
        "content-type": "application/json",
        Authorization: authorization ? "Bearer " + authorization : "",
      },
      body: JSON.stringify({
        groupNickname: newGroupNickname,
      }),
    }).then(() => {
      setGroupNickname(newGroupNickname ? newGroupNickname : "");
    });
  };

  const changeLogoImgBtn = (e) => {
    const formData = new FormData();
    formData.append("postImgs", e.target.files[0]);
    fetch(`https://api.newsalji.com/api/v1/uploader/posts`, {
      method: "POST",
      headers: {
        Authorization: authorization ? "Bearer " + authorization : "",
      },
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const newLogo = data.data.locations[0];
        fetch(`https://api.newsalji.com/api/v1/auth/providers`, {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            Authorization: authorization ? "Bearer " + authorization : "",
          },
          body: JSON.stringify({
            logoImgUrl: newLogo,
          }),
        })
          .then((res) => res.json())
          .then((data) => {
            location.reload();
          });
      });
  };

  return (
    <Layout>
      <div className="h-20"></div>
      <div className="flex flex-col items-center h-[250px] justify-center">
        <img src={logo} className="w-[133px] h-[133px]" />
        <div className="text-[20px] font-[700] mt-[10px]">{groupNickname}</div>
      </div>
      <div className="flex justify-center text-[#374151] font-[600] text-[20px] text-center">
        <div
          onClick={clickChangeGroupNicknameBtn}
          className="hover:cursor-pointer w-[160px] h-[46px] border-solid border-[1px] border-primary rounded-[8px] align-middle leading-[46px] mx-[5px]"
        >
          소속명 변경
        </div>
        <div>
          <label
            htmlFor="chooseFile"
            className="
          w-[160px] h-[46px] border-solid border-[1px] border-primary rounded-[8px] block align-middle leading-[46px] mx-[5px] hover:cursor-pointer"
          >
            소속 로고 변경
          </label>
          <input
            id="chooseFile"
            type="file"
            className="hidden"
            onChange={changeLogoImgBtn}
          />
        </div>
      </div>
    </Layout>
  );
}

export default ProfilePage;
