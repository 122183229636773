import React from "react";

const InputBox = ({
  title,
  subTitle,
  placeHolder,
  onChange,
  name,
  value,
  isError = false,
  maxLength,
}) => {
  return (
    <div className="my-6 mb-2">
      <div className="text-left font-semibold my-2">
        <span className="text-gray-800 text-xl">{title}</span>
        <span className="text-gray-600 text-xs mx-2">{subTitle}</span>
      </div>
      <input
        type="text"
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeHolder}
        maxLength={maxLength}
        className={`input input-bordered ${
          isError ? "input-error" : "input-primary"
        } w-full `}
      />
    </div>
  );
};

export default InputBox;
