import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import GetPostsPage from './pages/getPosts';
import LoginPage from './pages/login';
import CreatePostPage from './pages/createPost';
import FirstContainer from './pages/createPost/FirstContainer';
import ProfilePage from './pages/profile';

function App() {
  function PrivateRoute({ children }) {
    let isAuth = localStorage.getItem('Authorization');
    return isAuth ? children : <Navigate to='/login' />;
  }

  function PublicRoute({ children }) {
    let isAuth = localStorage.getItem('Authorization');
    return isAuth ? <Navigate to='/' /> : children;
  }

  return (
    <div>
      <Routes>
        <Route
          path='/'
          element={
            <PrivateRoute>
              <GetPostsPage />
            </PrivateRoute>
          }
        />
        <Route
          path='/posts/create'
          element={
            <PrivateRoute>
              <CreatePostPage />
            </PrivateRoute>
          }
        />
        <Route
          path='/profile'
          element={
            <PrivateRoute>
              <ProfilePage />
            </PrivateRoute>
          }
        />
        <Route path='/login' element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default App;
