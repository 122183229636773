import React from "react";
import { useSearchParams } from "react-router-dom";
import Layout from "../../components/common/Layout";
import FirstContainer from "./FirstContainer";
import SecondContainer from "./SecondContainer";
import ThirdContainer from "./ThirdContainer";

const CreatePostPage = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  let contentComponent;
  if (searchParams.get("step") === "1") {
    contentComponent = <FirstContainer />;
  } else if (searchParams.get("step") === "2") {
    contentComponent = <SecondContainer />;
  } else if (searchParams.get("step") === "3") {
    contentComponent = <ThirdContainer />;
  }
  return (
    <Layout>
      <div className="flex justify-center">
        <div className="w-full text-center lg:w-[50%]">
          <div className="text-4xl text-gray-800 font-bold">소식 발행하기</div>
          {contentComponent}
        </div>
      </div>
    </Layout>
  );
};

export default CreatePostPage;
