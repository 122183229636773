import React from "react";

interface Category {
  id: number;
  name: string;
}

const defaultCategories: Category[] = [
  {
    id: 1,
    name: "카테고리1",
  },
];

const SelectBox = ({ onChange, isError = false }) => {
  const authorization = localStorage.getItem("Authorization");
  const [categories, setCategories] = React.useState(defaultCategories);

  React.useEffect(() => {
    fetch(`https://api.newsalji.com/api/v1/categories`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: authorization ? "Bearer " + authorization : "",
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setCategories(data.data);
      });
  }, []);

  return (
    <>
      <div className="text-left font-semibold mt-4">
        <span className="text-gray-800 text-xl ">관심사 카테고리 (필수)</span>
        <span className="text-gray-600 text-xs"></span>
      </div>
      <select
        onChange={onChange}
        className={`select  ${
          isError ? "select-error" : "select-primary"
        } w-full  text-gray-600 font-[400] mt-2`}
      >
        <option disabled selected className="text-gray-400">
          발행할 소식의 카테고리를 선택해주세요
        </option>
        {categories.map((category) => {
          return (
            <option value={category.id} key={category.id}>
              {category.name}
            </option>
          );
        })}
      </select>
    </>
  );
};

export default SelectBox;
