import { atom } from "recoil";

import { ParagraphState } from "types";

export const titleState = atom({
  key: "titleState",
  default: "",
});

export const subtitleState = atom({
  key: "subtitleState",
  default: "",
});

export const creatorState = atom({
  key: "creatorState",
  default: "",
});

export const categoryIdState = atom({
  key: "categoryIdState",
  default: 0,
});

export const linkState = atom({
  key: "linkState",
  default: "",
});

export const paragraphState = atom<ParagraphState[]>({
  key: "paragraphState",
  default: [
    {
      id: 1,
      title: "",
      content: "",
      titleEmoji: null,
    },
  ],
});
