import React from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import {
  CREATE_POST_STEP1_URL,
  CREATE_POST_STEP3_URL,
} from "../../common/ApiUrl";
import ParagraphInputBoxContainer from "./ParagraphInputBoxContainer";
import { paragraphState } from "../../state";

const SecondContainer = () => {
  const [paragraphs, setParagraphs] = useRecoilState(paragraphState);
  const disabled =
    paragraphs[0].content.length < 2 || paragraphs[0].title.length < 2;

  const validate = (e) => {
    if (disabled) {
      e.preventDefault();
      return;
    }
  };

  return (
    <>
      <div className="my-3 w-full">
        <ul className="steps w-full">
          <li className="step step-primary"></li>
          <li className="step step-primary"></li>
          <li className="step"></li>
        </ul>
      </div>
      <div className="text-sm text-primary">
        step 2. 발행할 소식의 문단 내용을 최소 하나 이상 입력해 주세요.
      </div>
      <ParagraphInputBoxContainer />

      <div className="flex justify-between w-full">
        <NavLink to={CREATE_POST_STEP1_URL}>
          <button className="btn btn-outline btn-primary w-[141px] my-6 mr-1">
            이전
          </button>
        </NavLink>
        <NavLink to={CREATE_POST_STEP3_URL}>
          <button
            className={`btn ${
              disabled ? "bg-gray-300" : "btn-primary"
            } w-[141px] my-6 ml-1 `}
            onClick={validate}
          >
            다음
          </button>
        </NavLink>
      </div>
    </>
  );
};

export default SecondContainer;
