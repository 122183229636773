import React from "react";
import TopBar from "./TobBar";
import Footer from "./Footer";

const Layout = ({ children }) => {
  return (
    <>
      <div className="flex justify-center w-full min-h-[70vh]">
        <div className="w-[90%]">
          <TopBar />
          {children}
        </div>
      </div>
      <div className="bg-black mt-[50px]">
        <Footer />
      </div>
    </>
  );
};

export default Layout;
