import React, { useState } from "react";
import logo from "static/imgs/horizontalLogo.svg";
import { NavLink } from "react-router-dom";
import {
  CREATE_POST_STEP1_URL,
  LOGIN_URL,
  PROFILE_URL,
} from "../../common/ApiUrl";

const TopBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);

  const logout = () => {
    localStorage.setItem("Authorization", "");
  };

  return (
    <div className="navbar bg-base-100">
      {/*로고*/}
      <div className="flex-1">
        <NavLink to="/">
          <img src={logo} className="h-[44px]" />
        </NavLink>
      </div>

      <div className="flex-none ">
        <div className="relative ">
          <div
            className="dropdown dropdown-end "
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <div
              tabIndex={0}
              className="btn m-1 border-0 rounded-lg hover:bg-gray-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block w-5 h-5 stroke-current"
                style={{ color: "#111827" }}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </div>
            {showDropdown && (
              <ul
                tabIndex={0}
                className="dropdown-content menu p-2 shadow bg-base-100 rounded-box w-52"
              >
                <li>
                  <NavLink
                    to="/"
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    소식 관리
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={PROFILE_URL}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    소속 정보
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to={CREATE_POST_STEP1_URL}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    소식 발행하기
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={logout}
                    to={LOGIN_URL}
                    className={({ isActive }) => (isActive ? "active" : "")}
                  >
                    로그아웃
                  </NavLink>
                </li>
              </ul>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBar;
