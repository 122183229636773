import React, { useState } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useRecoilValue, useRecoilState, useResetRecoilState } from "recoil";

import {
  CREATE_POST_STEP1_URL,
  CREATE_POST_STEP2_URL,
  CREATE_POST_STEP3_URL,
  HOME_URL,
} from "../../common/ApiUrl";
import {
  titleState,
  subtitleState,
  creatorState,
  categoryIdState,
  linkState,
  paragraphState,
} from "../../state";
import { Post } from "types";

const ThirdContainer = () => {
  const [images, setImages] = useState("");
  const [fileNames, setFileNames] = useState<any[]>([]);
  const [loadDone, setLoadDone] = useState(false);
  const [title, setTitle] = useRecoilState(titleState);
  const [postLoading, setPostLoading] = useState(false);
  const [subTitle, setSubTitle] = useRecoilState(subtitleState);
  const [creator, setCreator] = useRecoilState(creatorState);
  const [categoryId, setCategoryId] = useRecoilState(categoryIdState);
  const [link, setLink] = useRecoilState(linkState);
  const paragraph = useRecoilValue(paragraphState);
  const resetParagraph = useResetRecoilState(paragraphState);
  const navigate = useNavigate();

  const onLoadFile = (e) => {
    const files = e.target.files;
    setImages(files);
    const newFileNames: any = [];
    if (files.length < 12) {
      for (let i = 0; i < files.length; i++) {
        newFileNames.push(files[i].name);
      }
      setFileNames(newFileNames);
      setLoadDone(true);
    } else {
      alert("사진이 10장 이상입니다!");
    }
  };

  const reset = () => {
    setTitle("");
    setSubTitle("");
    setCreator("");
    setCategoryId(0);
    setLink("");
    resetParagraph();
  };

  const onClick = async () => {
    if (postLoading) return;
    setPostLoading(true);
    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      formData.append("postImgs", images[i]);
    }
    fetch("https://api.newsalji.com/api/v1/uploader/posts", {
      method: "POST",
      body: formData,
    })
      .then((res) => res.json())
      .then((data) => {
        const url = data.data.locations;
        const imgLinks: any[] = [];
        for (let i = 0; i < url.length; i++) {
          const newUrl = { link: url[i] };
          imgLinks.push(newUrl);
        }

        const posts: Post = {
          title: title,
          subTitle: subTitle,
          publisherName: creator,
          categoryId: categoryId,
          hyperlink: link,
          contents: paragraph,
          postImgs: imgLinks,
        };

        fetch("https://api.newsalji.com/api/v1/posts", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${localStorage.getItem("Authorization")}`,
            "content-type": "application/json",
          },
          body: JSON.stringify(posts),
        })
          .then((res) => res.json())

          .then((data) => {
            if (data.code === 400) {
              alert(
                "소식을 발행하는데 실패했습니다. 모든 항목을 빠짐없이 기입한 후 다시 시도해주세요."
              );
              setPostLoading(false);
            } else {
              reset();
              setPostLoading(false);
              navigate(HOME_URL);
            }
          })
          .catch((error) => {
            setPostLoading(false);
            alert("실패");
          });
      })
      .catch((error) => {
        setPostLoading(false);
        alert("실패");
      });
  };

  return (
    <>
      <div className="my-3 w-full">
        <ul className="steps w-full">
          <li className="step step-primary"></li>
          <li className="step step-primary"></li>
          <li className="step step-primary"></li>
        </ul>
      </div>
      <div className="text-sm text-primary">
        step 3. 발행할 소식의 이미지를 첨부해 주세요.
      </div>

      <div className="my-6">
        <div className="text-left font-semibold my-2">
          <span className="text-gray-800 text-xl">파일 첨부</span>
          <div className="text-sm text-red-600 font-bold mt-2">
            *이미지는 꼭 한번에 올려주시기 바라며, 현재 페이지를 새로고침할 경우
            이전에 작성하신 모든 내용이 초기화되니 유의해주시기 바랍니다.
          </div>{" "}
          <span className="text-gray-600 text-xs mx-2 whitespace-pre-line">
            {`
            - 1:1 비율 이미지 업로드 권장
            - 320*320px 이상 사이즈 권장
            - 업로드 가능 파일 형식 (PNG, JPG, JPEG)
            - 권장 비율 미준수시 상단에서 1:1 비율로 잘라서 노출
            - 최대 10장 이미지 첨부 가능
            - 이미지 당 최대 5MB 이하의 이미지 업로드 가능
            - 사진 미업로드 시 카테고리에 따른 기본 이미지 부여
            
            `}
          </span>
        </div>
        {!loadDone && (
          <form>
            <input
              type="file"
              className="cursor-pointer block w-full text-sm text-slate-500
              file:mr-4 file:py-2 file:px-4
              file:h-[46px] file:w-[286px]
              file:rounded-[8px] file:border-[1px] file:border-[#594BFE]
              file:text-sm file:font-semibold
              file:bg-white file:text-[#594BFE]
              hover:file:bg-[#594BFE] hover:file:text-white"
              multiple
              accept="image/jpg, image/jpeg, image/png"
              onChange={onLoadFile}
            />
          </form>
        )}
        {fileNames.map((name, id) => (
          <div key={id}>{name}</div>
        ))}
      </div>
      <div className="flex my-12 justify-between">
        <NavLink to={CREATE_POST_STEP2_URL}>
          <button className="btn btn-outline btn-primary w-[141px] mr-1">
            이전
          </button>
        </NavLink>
        {/* <NavLink to={HOME_URL}> */}
        <button onClick={onClick} className="btn btn-primary w-[141px] ml-1">
          {postLoading ? "로딩중..." : "발행하기"}
        </button>
        {/* </NavLink> */}
      </div>
    </>
  );
};

export default ThirdContainer;
