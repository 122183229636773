import React from "react";
import Layout from "../../components/common/Layout";
import PostTable from "./PostTable";

const GetPostsPage = () => {
  return (
    <Layout>
      <div className="h-20"></div>
      <PostTable />
    </Layout>
  )
}

export default GetPostsPage;